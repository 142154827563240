import { getCurrentYear } from 'site-modules/shared/utils/time-util';

export function getUsedRange({ totalRange, year, isUsed }) {
  const usedYears = isUsed ? getCurrentYear() - year : 0;

  return {
    usedYears,
    currentRange: Math.round(totalRange * (1 - 0.01875 * usedYears)),
  };
}
