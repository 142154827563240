import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import {
  EvRangeMapImagesModel,
  EvRangeMapImagesPaths,
  MAP_NOT_AVAILABLE,
} from 'client/data/models/ev-range-map-images';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Link } from 'site-modules/shared/components/link/link';
import { EVRangeMapImageUI } from 'site-modules/shared/components/ev-range-map-image/ev-range-map-image';
import { ShareThisChart } from 'site-modules/shared/components/share-this-chart/share-this-chart';
import { VisitorEntities } from 'client/data/models/visitor';

function RangeInsightUI({
  vehicleName,
  modelName,
  pathname,
  currentRange,
  rangeMapUrl,
  visitorLocation,
  onTooltipOpen,
  isTestedRange,
  isEstimatedRange,
  isPluginHybrid,
}) {
  const isMissingMapImage = rangeMapUrl && rangeMapUrl === MAP_NOT_AVAILABLE;
  const mapRangeMiles = rangeMapUrl && +get(rangeMapUrl.match(/\/(\d+)-miles\./), '[1]');
  const mapRangeMilesRoundTrip = mapRangeMiles && Math.ceil(mapRangeMiles / 50) * 25;

  return (
    <div className="range-insight">
      <div
        className={classnames({
          'heading-4 text-center': currentRange,
          'mb-0_25': currentRange && !isEstimatedRange,
          'mb-1': currentRange && isEstimatedRange,
          'mb-1_5': !currentRange,
        })}
      >
        {currentRange ? (
          <Fragment>
            {currentRange} miles
            {isEstimatedRange && (
              <TooltipItem
                className="top-0"
                item={{
                  placement: 'bottom',
                  text: (
                    <Fragment>
                      The range for a used {vehicleName} is estimated to be {currentRange} miles because electric cars
                      typically experience 1-2% of range loss per year, with slightly faster degradation over the first
                      50,000 miles as the car settles into its long term state, according to{' '}
                      <Link
                        className="text-underline"
                        to="https://www.recurrentauto.com/research/lessons-in-electric-car-battery-health"
                        target="_blank"
                        rel="nofollow"
                      >
                        Recurrent
                      </Link>
                      &apos;s study of 15,000 EVs.
                    </Fragment>
                  ),
                }}
                delay={{ show: 0, hide: 250 }}
                autohide={false}
                id="range-estimated"
                onTooltipOpen={onTooltipOpen}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>{isEstimatedRange ? 'Not available' : 'EPA Estimated Range not available'}</Fragment>
        )}
      </div>
      {!!currentRange && (
        <div className="medium text-center mb-1_5">
          {!isEstimatedRange && (
            <div className="mb-1">
              {isTestedRange ? (
                <Fragment>
                  Edmunds Tested Range
                  <TooltipItem
                    className="top-0 small"
                    item={{
                      placement: 'bottom',
                      text:
                        'Edmunds begins with a full battery charge and drives the vehicle on a mix of city and highway roads (approximately 60% city, 40% highway) until the battery is almost entirely empty. (We target 10 miles of remaining range for safety.) The miles traveled and the indicated remaining range are added together for the Edmunds Tested Range figure.',
                    }}
                    id="range-insight"
                    onTooltipOpen={onTooltipOpen}
                  />
                </Fragment>
              ) : (
                <Fragment>{isPluginHybrid ? 'EPA Estimated Electric Range' : 'EPA Estimated Range'}</Fragment>
              )}
            </div>
          )}
          <div className="medium">
            {isPluginHybrid ? (
              <Fragment>The {modelName} will automatically switch to gas when the electric battery is low.</Fragment>
            ) : (
              <Fragment>
                EV batteries lose 1-2% of range per year.{' '}
                <Fragment>
                  {isEstimatedRange
                    ? `Est. range for this car is ${currentRange} miles.`
                    : `Est. range for this car is ${Math.round(currentRange * 0.85)} miles after 8 years.`}
                </Fragment>
              </Fragment>
            )}
            <TooltipItem
              className="top-0"
              item={{
                placement: 'bottom',
                text: (
                  <Fragment>
                    Electric cars typically experience 1-2% of range loss per year with slightly faster degradation over
                    the first 50,000 miles as the car settles into its long term state, according to{' '}
                    <Link
                      className="text-underline"
                      to="https://www.recurrentauto.com/research/lessons-in-electric-car-battery-health"
                      target="_blank"
                      rel="nofollow"
                    >
                      Recurrent
                    </Link>
                    &apos;s study of 15,000 EVs.
                  </Fragment>
                ),
              }}
              delay={{ show: 0, hide: 250 }}
              autohide={false}
              id="range-battery"
              onTooltipOpen={onTooltipOpen}
            />
          </div>
        </div>
      )}

      {!isMissingMapImage && <EVRangeMapImageUI url={rangeMapUrl} className="mb-1" />}
      <div className="rounded-10 bg-cool-gray-100 py-1 px-0_5 text-center">
        {isMissingMapImage ? (
          'No range map available for'
        ) : (
          <Fragment>
            Estimated range map
            <TooltipItem
              className="top-0"
              item={{
                placement: 'bottom',
                text: (
                  <Fragment>
                    This map is a visual representation of the possible one-way and round-trips by this vehicle (on a
                    full charge) from the geometric center of {get(visitorLocation, 'city')},{' '}
                    {get(visitorLocation, 'state')}. The depicted ranges are based on the estimated new vehicle range
                    value provided by the EPA, rounded down to {mapRangeMiles} miles for one-way and{' '}
                    {mapRangeMilesRoundTrip} miles for round-trip. Actual range will vary depending on the condition of
                    this vehicle’s battery pack, how you drive, driving conditions and other factors.
                    <div>
                      Map display generated using: ©&nbsp;
                      <Link to="openrouteservice.org" className="text-underline" rel="nofollow" target="_blank">
                        openrouteservice.org
                      </Link>{' '}
                      by HeiGIT | Map data ©&nbsp;
                      <Link
                        to="https://www.openstreetmap.org/#map=5/38.007/-95.844"
                        className="text-underline"
                        rel="nofollow"
                        target="_blank"
                      >
                        OpenStreetMap
                      </Link>{' '}
                      contributors |{' '}
                      <Link to="https://leafletjs.com/" className="text-underline" rel="nofollow" target="_blank">
                        Leaflet
                      </Link>
                    </div>
                  </Fragment>
                ),
              }}
              id="range-map"
              delay={{ show: 0, hide: 250 }}
              autohide={false}
              onTooltipOpen={onTooltipOpen}
            />{' '}
            from
          </Fragment>
        )}{' '}
        <ZipWithDefaultActionHandling buttonClassName="size-16 font-weight-normal" />
      </div>

      {!isMissingMapImage && (
        <ShareThisChart
          pageTitle={vehicleName}
          pageUrl={`https://www.edmunds.com${pathname}#subnav-ev-insights`}
          shareText="Share"
          className={classnames('text-blue-40 rounded', {
            'mx-auto mt-1': isTestedRange,
            'pos-a top-0 right-0 mt-1_5 mr-1_5': !isTestedRange,
          })}
          trackingId="view_share_options"
          socialIcons={['facebook', 'x', 'linkedin', 'reddit', 'email']}
          socialTrackingId="share_range_map"
        />
      )}
    </div>
  );
}

RangeInsightUI.propTypes = {
  vehicleName: PropTypes.string,
  modelName: PropTypes.string,
  pathname: PropTypes.string,
  currentRange: PropTypes.number,
  rangeMapUrl: PropTypes.string,
  visitorLocation: VisitorEntities.Location,
  onTooltipOpen: PropTypes.func,
  isTestedRange: PropTypes.bool,
  isEstimatedRange: PropTypes.bool,
  isPluginHybrid: PropTypes.bool,
};

RangeInsightUI.defaultProps = {
  vehicleName: null,
  modelName: null,
  pathname: null,
  currentRange: null,
  rangeMapUrl: null,
  visitorLocation: null,
  onTooltipOpen: null,
  isTestedRange: false,
  isEstimatedRange: false,
  isPluginHybrid: false,
};

export const propsAreEqual = (prevProps, { rangeMapUrl }) => !rangeMapUrl;
export const RangeInsight = connectToModel(React.memo(RangeInsightUI, propsAreEqual), {
  pathname: bindToPath('location.pathname', PageModel),
  rangeMapUrl: bindToPath(
    ({ currentRange }) => EvRangeMapImagesPaths.RANGE_MAP_CURRENT_CITY(currentRange),
    EvRangeMapImagesModel
  ),
});
