import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { VisitorModel } from './visitor';

export const MAP_NOT_AVAILABLE = 'N/A';
export const EvRangeMapImagesPaths = {
  RANGE_MAP_CURRENT_CITY: miles => `rangeMap["${miles}"].currentCity.url`,
  RANGE_MAP_CITY: ({ miles, state, city }) => `rangeMap["${miles}"]["${state}"]["${city}"].url`,
};

export const EvRangeMapImagesModel = createModelSegment('evRangeMapImages', [
  /**
   * EV range map API.
   * Example: https://qa-11-www.edmunds.com/api/ev-range/map/image?city=spokane&state=washington&range=600
   * @see EvRangeMapImagesPaths.RANGE_MAP_CITY
   * @return String|""
   */
  {
    path: 'rangeMap["{miles}"]["{state}"]["{city}"].url',
    async resolve({ miles, city, state }, context) {
      try {
        const url = `/ev-range/map/image?city=${city}&state=${state}&range=${miles}`;
        const response = await withMetrics(EdmundsAPI, context).fetchJson(url);

        return get(response, 'url', MAP_NOT_AVAILABLE).replace(/unversioned\//, '');
      } catch (e) {
        return MAP_NOT_AVAILABLE;
      }
    },
  },
  /**
   * @see EvRangeMapImagesPaths.RANGE_MAP_CURRENT_CITY
   */
  {
    path: 'rangeMap["{miles}"].currentCity.url',
    async resolve({ miles }, context) {
      const { city, state } = await context.resolveValue('location', VisitorModel);

      return context
        .resolveValue(EvRangeMapImagesPaths.RANGE_MAP_CITY({ miles, city, state }))
        .then(() => ({ $ref: `#/rangeMap/${miles}/${state}/${city}/url` }));
    },
  },
]);
